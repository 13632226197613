import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Sun, Moon } from "./svg";

const colorModeBtn = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 0,
  margin: "1px auto 0",
  fontSize: "1rem",
  fontWeight: 600,
  lineHeight: 1,
  cursor: "pointer",
  background: "none",
  border: "none",
});

const LightDark = styled.div`
  position: absolute;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;

  @media (max-width: 991px) {
    top: 0.85rem;
    right: 5rem;
  }

  @media (min-width: 992px) {
    top: auto;
    right: 1rem;
  }
`;

function ThemeToggler({ theme, onClick }) {
  const nextTheme = theme === "light" ? "dark" : "light";
  const sunMoon = theme === "light" ? <Moon /> : <Sun />;
  return (
    <button
      aria-label="Toggle Site Theme"
      onClick={() => onClick(nextTheme)}
      css={colorModeBtn}
    >
      {sunMoon}
    </button>
  );
}

export default function DarkMode({ theme, onClick }) {
  return (
    <LightDark>
      <ThemeToggler theme={theme} onClick={onClick} />
    </LightDark>
  );
}
