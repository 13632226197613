import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const AvatarContainer = styled.div`
  position: absolute;
  left: 50%;
  width: 3.125rem;
  bottom: -1.5rem;
  transition: opacity 0.5s ease-in-out;

  @media (min-width: 992px) {
    width: 6.25rem;
    bottom: -1.9375rem;
  }

  .avatar-img-border {
    width: 100%;
    margin-left: -50%;
    border-radius: 50%;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.8);

    @media (min-width: 992px) {
      width: 100%;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    border-radius: 50%;
    display: block;

    @media (min-width: 992px) {
      width: 100%;
    }
  }
`;

const noLogo = css({
  opacity: 0,
  visibility: "hidden",
  transition: "visibility linear 0.5s, opacity 0.5s ease-in-out",
});

export default function Avatar({ padding, expanded, theme }) {
  const data = useStaticQuery(graphql`
    query {
      lightLogo: file(relativePath: { eq: "logo192px.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, maxHeight: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      darkLogo: file(relativePath: { eq: "darklogo192px.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, maxHeight: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <AvatarContainer
      css={(padding === "short") | (expanded === true) ? noLogo : ""}
    >
      <div className="avatar-img-border">
        <Link title="Watchung Co-op" to="/">
          {theme === "light" ? (
            <Img
              fluid={data.lightLogo.childImageSharp.fluid}
              alt="Watchung Co-op Logo"
              className="avatar-img"
            />
          ) : (
            <Img
              fluid={data.darkLogo.childImageSharp.fluid}
              alt="Watchung Co-op Logo"
              className="avatar-img"
            />
          )}
        </Link>
      </div>
    </AvatarContainer>
  );
}
