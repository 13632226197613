import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Burger } from "./svg";
import DarkMode from "./darkmode";
import Avatar from "./avatar";

const Dropdown = (props) => (
  <NavDropdown
    title={props.title}
    id={"basic-nav-dropdown-" + props.title}
    css={(theme) => ({
      textTransform: "uppercase",
      fontSize: "0.8125rem",
      letterSpacing: "0.0625rem",
      "@media(min-width: 992px)": {
        paddingLeft: ".9rem",
        paddingRight: ".9rem",
      },
      "&:hover,&:focus": {
        color: theme.colors.tertiary,
      },
      "& .dropdown-menu": {
        minWidth: 0,
        marginTop: 0,
        fontSize: "1em",
        border: 0,
        padding: 0,
        width: "100%",
        wordBreak: "normal",
        hyphens: "none",
        background: theme.colors.primary,
        "@media(min-width: 992px)": {
          textAlign: "center",
        },
        "& .dropdown-item": {
          whiteSpace: "normal",
          padding: "0.625rem",
          background: theme.colors.primary,
          textDecoration: "none",
          borderWidth: "0 1px 1px 1px",
          fontWeight: 400,
          color: theme.colors.surface3,
          "@media(max-width: 991px)": {
            paddingLeft: "2rem",
          },
          "@media(min-width: 992px)": {
            paddingLeft: "0.625rem",
            border: "1px solid",
            borderColor: theme.colors.element4,
            borderWidth: "0 1px 1px",
          },
        },
        "& .nav-link": {
          lineHeight: "1.25rem",
          color: theme.colors.surface3,
          "@media(min-width: 992px)": {
            paddingTop: "0.9375rem",
            paddingBottom: "0.9375rem",
          },
        },
      },
    })}
  >
    {props.children}
  </NavDropdown>
);

export default function Navigation({ theme, onClick }) {
  const navPadding = {
    full: {
      paddingTop: "1.25rem",
      paddingBottom: "1.25rem",
    },
    short: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  };
  const [padding, setPadding] = useState("full");

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = Math.abs(window.scrollY) > 50;
      isScrolled ? setPadding("short") : setPadding("full");
    };
    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [padding]);

  const [expanded, setExpand] = useState(false);
  const burgerToggle = expanded !== true ? true : false;
  return (
    <Navbar
      expand="lg"
      fixed="top"
      variant=""
      css={(theme) => ({
        background: theme.colors.primary,
        borderBottom: "1px solid",
        borderBottomColor: theme.colors.element4,
        fontFamily: "cooper hewitt,helvetica neue,helvetica,arial,sans-serif",
        transition: "padding .5s ease-in-out",
        padding: "0 1rem",
        "& .navbar-brand, & .nav-link": {
          fontWeight: 800,
          color: theme.colors.surface3,
        },
        "& .nav-link": {
          padding: ".675rem 0 .675rem 1rem",
        },
        "@media(min-width: 992px)": {
          paddingTop: navPadding[padding].paddingTop,
          paddingBottom: navPadding[padding].paddingTop,
          /*height: '5.6875rem'  */
        },
      })}
    >
      <Navbar.Brand
        as={Link}
        to="/"
        css={{
          lineHeight: 1.5,
          paddingTop: "0.625rem",
          paddingBottom: "0.625rem",
          fontSize: "1.125rem",
        }}
      >
        Watchung Co-op
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpand(burgerToggle)}
        css={{
          margin: "0.5rem 0",
          fontSize: "1rem",
        }}
      >
        <span className="sr-only">Toggle Navigation</span>
        <Burger />
      </Navbar.Toggle>
      <Navbar.Collapse
        id="basic-navbar-nav"
        css={(theme) => ({
          "@media(max-width: 991px)": {
            borderTop: "1px solid",
            borderTopColor: theme.colors.element4,
            margin: "0 -1rem",
          },
          "@media(min-width: 992px)": {
            marginRight: "2.3rem",
          },
        })}
      >
        <Nav
          className="ml-auto"
          css={{
            "@media(max-width: 991px)": {
              padding: ".5rem 0",
            },
          }}
        >
          <Dropdown title="About">
            <NavDropdown.Item as={Link} to="/welcome">
              Director's Message
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/staff">
              Staff
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/classes">
              Classes
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/parents">
              Role of Parents
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/musictogether">
              Music Together
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/enrichment">
              Enrichment
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gallery">
              Gallery
            </NavDropdown.Item>
          </Dropdown>
          <Dropdown title="Join Us">
            <NavDropdown.Item as={Link} to="/visit">
              Visit
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/tuition">
              Tuition
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/apply">
              Apply
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/support">
              Support the Co-op
            </NavDropdown.Item>
          </Dropdown>
          <Dropdown title="News">
            <NavDropdown.Item href="https://sites.google.com/view/watchungcoop-hub/home">
              Parent Hub
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/calendar">
              Calendar
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/covid19">
              COVID-19 Protocol
            </NavDropdown.Item>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
      <DarkMode theme={theme} onClick={onClick} />
      <Avatar theme={theme} padding={padding} expanded={expanded} />
    </Navbar>
  );
}
