import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Global, ThemeProvider } from "@emotion/react";
import "../css/typography.css";
import { themes } from "./themes";
import Navigation from "./nav";
import Footer from "./footer";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Layout({ children }) {
  const storedTheme = typeof window !== 'undefined' && window.localStorage;
  const [theme, setTheme] = useState(storedTheme ? localStorage.getItem("theme") : "light");
  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);
  return (
    <ThemeProvider theme={themes[theme]}>
      <Helmet>
        <html lang="en" amp />
        <title>Home | Watchung Co-op</title>
      </Helmet>
      <Global
        styles={(theme) => ({
          body: {
            fontFamily: "source serif pro, times new roman, serif",
            fontSize: "1.2rem",
            hyphens: "auto",
            fontKerning: "normal",
            color: theme.colors.element1,
            position: "relative",
            background: theme.colors.surface4,
          },
          "h1, h2, h3, h4": {
            marginTop: "1.25rem",
            fontFamily:
              "cooper hewitt,helvetica neue,helvetica,arial,sans-serif",
            fontKerning: "auto",
            fontWeight: 800,
          },
          h2: {
            color: theme.colors.pager,
          },
          a: {
            color: theme.colors.secondary,
            "&:hover,&:focus": {
              color: theme.colors.tertiary,
            },
          },
        })}
      />
      <Navigation theme={theme} onClick={(nextTheme) => setTheme(nextTheme)} />
      {children}
      <Footer />
    </ThemeProvider>
  );
}
