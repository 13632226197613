export const themes = {
  light: {
    colors: {
      surface1: "#e6e6e6",
      surface2: "#f2f2f2",
      surface3: "#f9f9f9",
      surface4: "#fbfbfb",
      element1: "#111111",
      element2: "#222222",
      element3: "#333333",
      element4: "#d8d8d8",
      primary: "#04378b",
      secondary: "#007cc4",
      tertiary: "#00bed4",
      quaternary: "#6bfbce",
      pager: "#04378b",
    },
  },
  dark: {
    colors: {
      surface1: "#262626",
      surface2: "#333333",
      surface3: "#C1CAE6",
      surface4: "#101010",
      element1: "#eeeeee",
      element2: "#dddddd",
      element3: "#cccccc",
      element4: "#808080",
      primary: "#262626",
      secondary: "#98dbfe",
      tertiary: "#61eef7",
      quaternary: "#71faca",
      pager: "#c1cae6",
    },
  },
};
