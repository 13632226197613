import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col, ListGroup, ListGroupItem } from "react-bootstrap";
import { Email, Facebook, Instagram, Twitter, YouTube } from "./svg";
import "bootstrap/dist/css/bootstrap.min.css";

const Contact = (props) => (
  <p
    css={(theme) => ({
      textAlign: "center",
      marginBottom: "0.9375rem",
      margin: "10px 0 0",
      color: theme.colors.surface3,
    })}
  >
    <a href={props.map}>{props.address}</a>
    &nbsp;&bull;&nbsp;
    <a href={"tel:" + props.phone}>{props.phone}</a>
  </p>
);

const Copyright = (props) => (
  <p
    css={(theme) => ({
      textAlign: "center",
      marginBottom: 0,
      marginTop: 0,
      color: theme.colors.surface3,
    })}
  >
    &copy; {props.year}&nbsp;&bull;&nbsp;{props.author}
  </p>
);

const SocialLink = (props) => (
  <ListGroupItem
    css={(theme) => ({
      background: theme.colors.primary,
      border: "none",
      padding: "0 0.5rem",
    })}
  >
    <a href={props.href} title={props.title}>
      {props.children}
    </a>
  </ListGroupItem>
);

const SocialLinks = ({ social }) => {
  const socialProps = [
    {
      href: "mailto:" + social.email,
      title: "Email the Co-op",
      icon: <Email />,
    },
    {
      href: "https://www.facebook.com/" + social.facebook,
      title: "Visit the Co-op's Facebook Page",
      icon: <Facebook />,
    },
    {
      href: "https://www.instagram.com/" + social.instagram,
      title: "Visit the Co-op's Instagram Feed",
      icon: <Instagram />,
    },
    {
      href: "https://www.twitter.com/" + social.twitter,
      title: "Visit the Co-op's Twitter Feed",
      icon: <Twitter />,
    },
    {
      href: "https://www.youtube.com/" + social.youtube,
      title: "Visit the Co-op's YouTube Channel",
      icon: <YouTube />,
    },
  ];

  const socialList = socialProps.map((item) => (
    <SocialLink key={item.href.toString()} href={item.href} title={item.title}>
      {item.icon}
    </SocialLink>
  ));

  return (
    <ListGroup
      horizontal
      css={(theme) => ({
        background: theme.colors.primary,
        padding: 0,
        border: "none",
        justifyContent: "center",
        marginBottom: "1.875rem",
        "& .list-group-item": {
          background: theme.colors.primary,
          border: "none",
          padding: "0 0.5rem",
        },
      })}
    >
      {socialList}
    </ListGroup>
  );
};

export default function Footer() {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        buildTime(formatString: "YYYY")
        siteMetadata {
          fullTitle
          address
          map
          phone
          email
          facebook
          instagram
          twitter
          youtube
        }
      }
    }
  `);
  return (
    <footer
      css={(theme) => ({
        padding: "1.875rem 0",
        background: theme.colors.primary,
        borderTop: "1px solid",
        borderTopColor: theme.colors.element4,
        marginTop: "3.125rem",
        fontFamily: "cooper hewitt,helvetica neue,helvetica,arial,sans-serif",
        fontKerning: "auto",
        fontSize: "1.125rem",
        a: {
          color: theme.colors.surface3,
          "&:hover,&:focus": {
            color: theme.colors.tertiary,
          },
        },
        "@media(min-width: 992px)": {
          padding: "3.125rem 0",
        },
      })}
    >
      <Container>
        <Row>
          <Col xl={{ span: 8, offset: 2 }} lg={{ span: 10, offset: 1 }}>
            <SocialLinks social={data.site.siteMetadata} />
            <Copyright
              year={data.site.buildTime}
              author={data.site.siteMetadata.fullTitle}
            />
            <Contact
              map={data.site.siteMetadata.map}
              address={data.site.siteMetadata.address}
              phone={data.site.siteMetadata.phone}
            />
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
